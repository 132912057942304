import './Footer.css';
 
function Footer() {
  return (
    <footer className='footer'>
       <p className='footer__copyright'>© All Right Reserved&nbsp;&mdash; Caba Hub 2024</p>
    </footer>
  );
}
 
export default Footer