import { Route, Routes, useLocation } from 'react-router-dom';
import Main from '../Main/Main';
import './App.css';
import Footer from '../Footer/Footer';
import NotFound from '../NotFound/NotFound';
import Portfolio from '../Portfolio/Portfolio';
import ModalCloser from '../ModalCloser/ModalCloser';
import Header from '../Header/Header';
import AllProjects from '../AllProjects/AllProjects';
// import EquityProjects from '../EquityProjects/EquityProjects';
// import Technology from '../Technology/Technology';
import Partners from '../Partners/Partners';
// import Company from '../Company/Company';
import Contacts from '../Contacts/Contacts';
import Services from '../Services/Services';
import WhiteLabel from '../WhiteLabel/WhiteLabel';
import SolutionPage from '../WhiteLabel/SolutionPage/SolutionPage';

function App() {
  const location = useLocation();
  const { state } = location;

  return (
    <div className="app">
      {/* {state?.backgroundLocation ? <Header /> : null} */}
      <Header />
      <div className="app__content">
        <Routes location={location || state?.backgroundLocation}>
          <Route index path="/" element={<Main />} />
          <Route path="/*" element={<NotFound />} />
          <Route path="cases/:id" element={<Portfolio />} />
          <Route path="cases" element={<AllProjects />} />
          {/* <Route path="equity-projects" element={<EquityProjects />} /> */}
          {/* <Route path="technology" element={<Technology />} /> */}
          <Route path="partners" element={<Partners />} />
          {/* <Route path="company" element={<Company />} /> */}
          <Route path="contacts" element={<Contacts />} />
          <Route path="services" element={<Services />} />
          <Route path="white-label-solutions" element={<WhiteLabel />} />
          <Route
            path="white-label-solutions/:solution"
            element={<SolutionPage />}
          />
        </Routes>
        {state?.backgroundLocation && (
          <Routes>
            <Route path="cases/:id" element={<ModalCloser />} />
          </Routes>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default App;
